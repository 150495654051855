import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    FormText,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import MyS3Component from "../../components/s3/s3"
import axiosInstance from "../../axiosInterceptor";
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

const data = [
    { id: 1, image: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 1', action: 'edit,delete', status: 'active' },
    { id: 2, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 2', action: 'edit,delete', status: 'inactive' },
    { id: 3, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 3', action: 'edit,delete', status: 'active' },
    { id: 4, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 4', action: 'edit,delete', status: 'inactive' },
    { id: 5, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 5', action: 'edit,delete', status: 'active' },
    { id: 6, image: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 1', action: 'edit,delete', status: 'active' },
    { id: 7, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 2', action: 'edit,delete', status: 'inactive' },
    { id: 8, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 3', action: 'edit,delete', status: 'active' },
    { id: 9, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 4', action: 'edit,delete', status: 'inactive' },
    { id: 10, image: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 5', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];


const columns = [ 'Article', 'Image', 'action'];
const column=['articleName', 'thumbnail',  'action'];
const News = () => {
    const itemsPerPage = 5; // Number of items to display per page
    const [modal, setModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    console.log("selectedRow data", selectedRow);
    const [tableData, setTableData] = useState([]);
    const [stateformData, setFormData] = useState({});
    const [selectedNewsData, setSelectedNewsData] = useState({})
    console.log("formData is", stateformData);
    const [articleResData, SetArticleResData] = useState([]);
    const [isImagesVisible, setIsImagesVisible] = useState(true);
    console.log("BlogResData", articleResData)
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [updatedImageUrl,setUpdatedImageUrl]=useState({})
   

    const toggle = () => setModal(!modal);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    useEffect(() => {
        ArticleGetApi()
    }, []);
    const token = localStorage.getItem('JWT');
    const ArticleGetApi = () => axiosInstance.get("/article/getNA").then(response => {
        console.log("services response DATA", response.data);
        SetArticleResData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
        // setResponseData(r)

    }).catch(error => {
        console.log("Error from API", error);
    });
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = articleResData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
        try {
            const response = await axiosInstance.get(`/article/getNA?newsCode=${row.newsCode}`);
            setSelectedNewsData(response.data.data[0]);
        } catch (error) {
            console.log("Error fetching service data by code:", error);
        }
    };

    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };
    const handleCreate = async () => {
        setIsClicked(true);

        // Validation checks
        const validationErrors = {};
        if (stateformData.articleName?.length < 5) {
            validationErrors.articleName = "Input must be at least 5 characters long";
        }
        if (!stateformData.articleName) {
            validationErrors.articleName = 'Please fill this field';
        }
        if (stateformData.content?.length < 5) {
            validationErrors.content = "Input must be at least 5 characters long";
        }
        if (!stateformData.content) {
            validationErrors.content = 'Please fill this field';
        }
        if (stateformData.link?.length < 5) {
            validationErrors.link = "Input must be at least 5 characters long";
        }
        if (!stateformData.link) {
            validationErrors.link = 'Please fill this field';
        }
        if (!stateformData.image_url) {
            validationErrors.image_url = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "/article/nACreation";
        try {
            const response = await axiosInstance.post(api, stateformData, config);
            console.log("Testimonial creation response DATA", response.data);
            ArticleGetApi();
            toggle();
            setFormData({});
            // Display success popup
        } catch (error) {
            console.log("Article creation Error from API", error);
            
        }
    };
    const handleRemoveImage = () => {
        setIsImageVisible(false);
    };
    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if (inputName === 'thumbnail') {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, // Update validation status based on whether the field has a value
        }));
    };
    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            articleName: { required: true, minLength: 5 },
            content: { required: true, minLength: 5 },
            link: { required: true, minLength: 5 },
            image_url: { required: true }
        };

        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateformData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const formData = new FormData();
        formData.append("newsCode", selectedRow.newsCode);
        formData.append("articleName", stateformData.articleName || selectedRow.articleName);
        formData.append("content", stateformData.content || selectedRow.content);
        formData.append("link", stateformData.link || selectedRow.link);
        formData.append("image_url", stateformData.image_url || "");

        try {
            const response = await axiosInstance.put("/article/nAUpdate", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token": token
                }
            });
            const updatedAuthorImage = response.data.thumbnail;
            setUpdatedImageUrl(updatedAuthorImage);

            console.log("Edit award response:", response.data);
            ArticleGetApi();
            closeEditModal();
        } catch (error) {
            console.error("Error updating Article:", error);
            
        }
    };

   
    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete("/article/deleteNa",{ newsCode: selectedRow.newsCode }, {
                headers: {
                    'x-auth-token': token,
                    "Content-Type": 'application/json'
                }
            }
            );
            console.log("Delete testimonial response:", response.data);
            ArticleGetApi();
            closeDeleteModal();
           
        } catch (error) {
            console.log("Error deleting testimonial:", error);
           
        }
    };
    return (
        <>
            {/* <Header /> */}
            {/* Page content */}
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">News</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {column.map((column, columnIndex) => (
                                                    <td key={columnIndex}>
                                                        {column === 'action' ? (
                                                            <>
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                                </button>
                                                            </>
                                                        ) :
                                                            column === 'thumbnail' ? (
                                                                <MyS3Component key={row['newsCode']} imageUrl={row['thumbnail']} />
                                                            ) : column === 'newsStatus' ? (
                                                                <>
                                                                    <CustomInput type="switch" id={`customSwitch${rowIndex}`} checked={row['newsStatus'] === 'ACTIVE'} />
                                                                </>
                                                            ) : (
                                                                row[column]
                                                            )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    articleResData?.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={articleResData?.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Services</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                        <Col lg="6">
                                <FormGroup>
                                    <label
                                       className="form-control-label"
                                        htmlFor="name"
                                    >
                                        Article Name
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.articleName ? (isValidate.articleName?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        id="Description"
                                        placeholder="Please Enter Description"
                                        type="text"
                                        name="articleName"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, articleName: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.articleName && isValidate.articleName?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.articleName}</small>
                                    )}
                                    
                                </FormGroup>
                            </Col>
                            
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                       className="form-control-label"
                                        htmlFor="Content"
                                    >
                                        Content
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.content ? (isValidate.content?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        
                                        id="Description"
                                        placeholder="Please Enter Content"
                                        type="textarea"
                                        name="content"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, content: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.content && isValidate.content?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.content}</small>
                                    )}
                                    
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label for="image_url">Image</label>
                                    <Input type="file" id="image_url" name="image_url" onBlur={() => setIsValidate({ ...isValidate, image_url: true })} onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" required aria-describedby="name-error" />
                                    <FormText color="muted">

                                        Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.image_url && isValidate.image_url?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_url}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                       className="form-control-label"
                                        htmlFor="Link"
                                    >
                                        Link
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.link ? (isValidate.link?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        
                                        id="Description"
                                        placeholder="Please Enter Content"
                                        type="textarea"
                                        name="link"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, link: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.link && isValidate.link?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.link}</small>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                    <Row>
                        <Col lg="6">
                                <FormGroup>
                                    <label
                                       className="form-control-label"
                                        htmlFor="name"
                                    >
                                        Article Name
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.articleName ? (isValidate.articleName?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        id="Description"
                                        placeholder="Please Enter Description"
                                        type="text"
                                        name="articleName"
                                        defaultValue={selectedNewsData.articleName || ""}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, articleName: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.articleName && isValidate.articleName?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.articleName}</small>
                                    )}
                                    
                                </FormGroup>
                            </Col>
                            
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                       className="form-control-label"
                                        htmlFor="Content"
                                    >
                                        Content
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.content ? (isValidate.content?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        
                                        id="Description"
                                        placeholder="Please Enter Content"
                                        type="textarea"
                                        name="content"
                                        defaultValue={selectedNewsData.content || ""}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, content: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.content && isValidate.content?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.content}</small>
                                    )}
                                    
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label for="TestimonialThumbnail">Image</label>
                                    <Input type="file"
                                        id="image_url"
                                        name="image_url"
                                        onBlur={() => setIsValidate({ ...isValidate, image_url: true })}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg"
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                        Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedImageUrl || selectedNewsData.thumbnail) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedNewsData?.newsCode} imageUrl={selectedNewsData?.thumbnail ? selectedNewsData?.thumbnail : updatedImageUrl} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                    {isClicked && isValidate.image_url && isValidate.image_url?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.image_url}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                       className="form-control-label"
                                        htmlFor="Link"
                                    >
                                        Link
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.link ? (isValidate.link?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        
                                        id="Description"
                                        placeholder="Please Enter Content"
                                        type="textarea"
                                        name="link"
                                        defaultValue={selectedNewsData.link || ""}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, link: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                    {isClicked && isValidate.link && isValidate.link?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.link}</small>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                    <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                    <ModalBody className="bg-secondary">
                        Are you sure you want to delete this row?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Delete
                        </Button>{" "}
                        <Button color="secondary" onClick={closeDeleteModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default News;