
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axiosInstance from '../../axiosInterceptor';

const Login = () => {
  const navigate = useNavigate();
  const [logindata,Setlogindata] = useState({});
  const [validationMessage, setValidationMessage] = useState("");

  const handleUserLogin = async () => {
  const loginrequestdata = {
    ...logindata,
    user_usertype: 'ADMIN'
  };

  const config = {
    headers: {
      "content-type": "application/json"
    }
  };

  try {
    const response = await axiosInstance.post("/login/login", loginrequestdata, config);
    console.log("response.status", response.status);
    if (response.status === 200) {
      localStorage.setItem("JWT", response.data?.data.JWT);
      navigate("/admin/award");
    } else if (response.status === 400) {
      setValidationMessage(response.data?.message || "Invalid Credentials");
    }
  } catch (error) {
    console.log("Error from API", error);
    setValidationMessage("Invalid Credentials");
  }
};


  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
          <h2 className="text-center">Hello Admin!</h2>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="user_email"
                    onChange={(e)=> Setlogindata({...logindata,user_email: e.target.value})}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    name="user_password"
                    onChange={(e)=> Setlogindata({...logindata,user_password: e.target.value})}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                {/* <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label> */}
              </div>
              
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={() => handleUserLogin()}>
                  Sign in
                </Button>
              </div>
              {validationMessage && (
              <div className="text-center text-danger mb-3">{validationMessage}</div>
              )}
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Login;

