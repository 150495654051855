import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
const { REACT_APP_AWS_ACCESS_KEY_ID,REACT_APP_AWS_SECRET_KEY ,REACT_APP_API_REGION,REACT_APP_S3_BUCKETNAME} = process.env;
console.log("REACT_APP_AWS_ACCESS_KEY_ID",REACT_APP_AWS_ACCESS_KEY_ID)
function MyS3Component(value) {
    const [imageURL, setImageURL] = useState(null);
  useEffect(() => {
    console.log('Generating signed URL...',value.imageUrl);
    const s3 = new AWS.S3({
        accessKeyId: "AKIAQHQZ3PXDBYYRXDWG",
        secretAccessKey: "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",
        region: "ap-south-1",
      });
  
      const bucketName = "mtt-website-dev";
      const objectKey = value.imageUrl.toString().split('/').slice(3).join('/');
    // const objectKey = value.imageUrl.split('/').slice(3).join('/');
      console.log("objectKey",objectKey)
      const params = {
        Bucket: "mtt-website-dev",
        Key: objectKey,
        Expires: 300, // URL expiration time in seconds
        ResponseContentType: 'image/png'
      };  
    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        console.error('Error generating signed URL:', err);
      } else {
        console.log('Signed URL:', url);
        setImageURL(url);
      }
    });
  }, []);
  

  return (
    <div>
      {imageURL && (
        <img
        src={imageURL}
        className="rounded-2 thumbnail-image" 
        style={{ objectFit: 'cover', width: 'auto', height: '50px',  }}
        alt="Thumbnail"
    />
      )}
    </div>
  );
};

export default MyS3Component;
