import axios from 'axios';
import Swal from 'sweetalert2';

const axiosInstance = axios.create({
  baseURL: 'https://api.mytalkingtree.com/api',
});
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("JWT");

    if (accessToken) {
      if (config.headers) config.headers.JWT = accessToken;
    }
    return config;
  },
  (error) => {
    console.log("REQUEST API FAILED", error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    console.log("SUCCESS RESPONSE DATA", response);
    if (response.config.method === 'post' || response.config.method === 'delete' || response.config.method === 'put') {
      if (response.data.status == 200 && response.data.message != "Token Generated") {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
    }

    return response;
  },
  (error) => {
    console.log("ERROR IN FETCHING DATA", error);
    if (error?.response?.data?.message === "Token is Not Valid") {
      localStorage.removeItem("JWT");
      Swal.fire({
        icon: 'error',
        title: 'Token Error',
        text: 'Your session has expired. Please login again.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      }).then(() => {
        // Redirect to the login page
        window.location.href = '/auth/login'; // Adjust the path as per your application
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
