import { useState, useEffect } from "react";
import { Editor } from "primereact/editor";
import MyS3Component from "../../components/s3/s3"
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Table, Alert,
    Label,
    FormText,
    FormFeedback
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from '../../axiosInterceptor';
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";


const columns = ['Blog Title', 'Blog Subject', 'Blog Image','Blog Description', 'Action'];
const column = ['blogName' , 'blogSubject', 'blogThumbnail','blogDescription', 'action'];

const initialFormData = {
    blog_list: [{
        bb_story_title: '',
        bb_story_description: ''
    }]
}

const Blog = () => {
    const itemsPerPage = 5; // Number of items to display per page
    const [modal, setModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    console.log("selectedRow data", selectedRow);
    const [tableData, setTableData] = useState([]);
    const [stateformData, setFormData] = useState(initialFormData);
    console.log("formData is", stateformData);
    const [blogResData, SetBlogResData] = useState([]);
    const [isImagesVisible, setIsImagesVisible] = useState(true);
    console.log("BlogResData", blogResData)
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [updatedImageUrl,setUpdatedImageUrl]=useState({})
   

    const toggle = () => setModal(!modal);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    useEffect(() => {
        blogGetApi()
    }, []);
    const token = localStorage.getItem('JWT');
    const blogGetApi = () => axiosInstance.get("/blogs/blogRetrieve").then(response => {
        console.log("services response DATA", response.data);
        SetBlogResData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
        // setResponseData(r)

    }).catch(error => {
        console.log("Error from API", error);
    });
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = blogResData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    const handleRemovedImage = () => {
        // Handle the logic to remove the image
        setIsImagesVisible(false);
    };
    const handleRemoveImage = () => {
        // Handle the logic to remove the image
        setIsImageVisible(false);
    };
    console.log("data", blogResData, column);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
    };

    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };
    const closeEditModal = () => {
        setEditModalOpen(false);
        setSelectedRow({});
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setSelectedRow({});
    };

    const handleCreate = async() => {
        setIsClicked(true);
        const validationErrors = {};
        if (stateformData.blogName?.length < 5) {
            validationErrors.blogName = "Input must be at least 5 characters long";
        }
        if (!stateformData.blogName) {
            validationErrors.blogName = 'Please fill this field';
        }
        if (stateformData.blogSubject?.length < 5) {
            validationErrors.blogSubject = "Input must be at least 5 characters long";
        }
        if (!stateformData.blogSubject) {
            validationErrors.blogSubject = 'Please fill this field';
        }
        if (!stateformData.blogDescription) {
            validationErrors.blogDescription = 'Please fill this field';
        }
        if (stateformData.blogDescription?.length < 5) {
            validationErrors.blogDescription = "Input must be at least 5 characters long";
        }
        if (!stateformData.blogThumbnail) {
            validationErrors.blogThumbnail = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "/blogs/blogCreation";
        try{
        console.log("stateformData",stateformData)
        const response=await axiosInstance.post(api, stateformData, config);
        console.log("Blog creation response DATA",response.data)
           blogGetApi();
            toggle();
            setFormData({});
           
        } catch(error) {
            console.log("Blog creation Error from API", error);
            
        }
    };
    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            blogName: { required: true, minLength: 5 },
            blogDescription: { required: true, minLength: 5 },
            blogSubject: { required: true, minLength: 5 },
            blogThumbnail: { required: true }
        };
        
        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateformData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        
        const api = "/blogs/blogUpdate";
        console.log("req data to edit api", selectedRow);
        try {
            const response = await axiosInstance.post(api, selectedRow, config);

            console.log("Edit service response:", response);
            const updatedImageUrl = response.data.blogThumbnail;
            setUpdatedImageUrl(updatedImageUrl);
            setSelectedRow({})
            blogGetApi();
            closeEditModal();
        } catch (error) {
            console.error("Error updating service:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.post("/blogs/blogDelete", { blogCode: selectedRow.blogCode });         
            console.log("Delete blog response:", response.data);    
            blogGetApi();
            closeDeleteModal();
           
        } catch (error) {
            console.log("Error deleting blog:", error);
           
        }
    };

    const handleAddNewStoryPoint = () => {
        setFormData((prevData) => {
            console.log("prevData is", prevData);
            const newStory = {
                bb_story_title: '',
                bb_story_description: ''
            };
            return {
                ...prevData,
                blog_list: [...prevData?.blog_list, newStory]
            };
        });
    };
    const handleEditNewStoryPoint = () => {
        setSelectedRow((prevData) => {
            console.log("prevData in edit is", prevData);
            const newStory = {
                bb_story_title: '',
                bb_story_description: ''
            };
            return {
                ...prevData,
                blog_list: [...prevData?.blog_list, newStory]
            };
        });
    };

    const handleStoryInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setFormData((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedStories = prevData.blog_list?.map((story, idx) => {
                console.log("STORY DATA", story);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...story, [field]: value });
                    return { ...story, [field]: value };
                }
                return story;
            });
            console.log("updated story", updatedStories);
            return {
                ...prevData,
                blog_list: updatedStories,
            }
        });
    };
    const handleEditStoryInputChange = (index, field, value) => {
        console.log("index ==========", index, field, value);

        setSelectedRow((prevData) => {
            console.log("prevData state data is", prevData);
            const updatedStories = prevData.blog_list?.map((story, idx) => {
                console.log("STORY DATA", story);
                if (String(idx) === String(index)) {
                    console.log("       return ing data", { ...story, [field]: value });
                    return { ...story, [field]: value };
                }
                return story;
            });
            console.log("updated story", updatedStories);
            return {
                ...prevData,
                blog_list: updatedStories,
            }
        });
    };
    const handleRemoveStory = (index) => {
        const updatedStories = [...stateformData.blog_list];
        updatedStories.splice(index, 1);
        setFormData({ ...stateformData, blog_list: updatedStories });
    };
    const handleEditRemoveStory = (index) => {
        const updatedStories = [...selectedRow.blog_list];
        updatedStories.splice(index, 1);
        setSelectedRow({ ...selectedRow, blog_list: updatedStories });
    };

    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if(inputName==='blogThumbnail'){
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, // Update validation status based on whether the field has a value
        }));
    };
    const handleEditInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        setSelectedRow((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val,
        }));
    };

    return (
        <>
            {/* <Header /> */}
            {/* Page content */}
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Blog</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns?.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData?.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {column?.map((column, columnIndex) => (
                                                    <td key={columnIndex}>
                                                        {column === 'action' ? (
                                                            <>
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                                </button>
                                                            </>
                                                        ) :
                                                            column === 'blogThumbnail' ? (
                                                                // <MyS3Component imageURL={row['image_url']} style={{ display: 'block', width: 60, height: 40 }} />
                                                                <MyS3Component key={row['blogCode']} imageUrl={row['blogThumbnail']} />
                                                            )  :
                                                                    column === "blogStatus" ? (
                                                                        <>
                                                                            <CustomInput type="switch" id={`customSwitch${rowIndex}`} checked={row['blogStatus'] === 'ACTIVE'} />
                                                                        </>
                                                                    ) :
                                                                        (
                                                                            row[column]
                                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>

                                </Table>
                                {
                                    blogResData?.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={blogResData?.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Stories</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Name"
                                    >
                                        Blog Title
                                    </label>
                                    <Input
                                     className={`form-control-alternative ${
                                        isValidate.blogName ? (isValidate.blogName?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        type="text"
                                        name="blogName"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, blogName: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.blogName && isValidate.blogName?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogName}</small>
                                )}
                                </FormGroup>
                            </Col>

                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Blog Description:
                                    </label>
                                    <textarea
                                         className={`form-control-alternative ${
                                            isValidate.blogDescription ? (isValidate.blogDescription?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        rows={6}
                                        style={{ width: "100%" }}

                                        placeholder="Please Enter description"
                                        value={stateformData.blogDescription}
                                        name="blogDescription"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, blogDescription: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.blogDescription && isValidate.blogDescription?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogDescription}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Blog Subject:
                                    </label>
                                    <textarea
                                         className={`form-control-alternative ${
                                            isValidate.blogSubject ? (isValidate.blogSubject?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        defaultValue=""
                                        id="Branch"
                                        rows={6}
                                        style={{ width: "100%" }}

                                        placeholder="Please Enter description"
                                        value={stateformData.blogSubject}
                                        name="blogSubject"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, blogSubject: true })}
                                        required
                                        aria-describedby="name-error"
                                    />
                                     {isClicked && isValidate.blogSubject && isValidate.blogSubject?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogSubject}</small>
                                )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <Label for="serviceimage">Blog Image</Label>
                                    <Input type="file" id="serviceimage" name="blogThumbnail" onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" onBlur={() => setIsValidate({ ...isValidate, blogThumbnail: true })}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.blogThumbnail && isValidate.blogThumbnail?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogThumbnail}</small>
                                )}
                                </FormGroup>
                            </Col>


                        </Row>

                        <Col lg="12">
                            {stateformData.blog_list?.map((story, index) => (
                                <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', position: 'relative' }}>
                                    <Button size="sm" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleRemoveStory(index)}>
                                        X
                                    </Button>
                                    <FormGroup>
                                        <label>Blog Title:</label>
                                        <Input
                                            type="text"
                                            name="bb_story_title"
                                            value={story.bb_story_title}
                                            onChange={(e) => handleStoryInputChange(index, 'bb_story_title', e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Blog Description:</label>
                                        <Editor key={index} onTextChange={(e) => handleStoryInputChange(index, 'bb_story_description', e.htmlValue)} style={{ height: '100px' }} name="bb_story_description" value={story.bb_story_description} />
                                    </FormGroup>
                                </div>
                            ))}
                        </Col>
                        <Button style={{ float: "right" }} color="info" onClick={handleAddNewStoryPoint}>
                            + Add Story
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Success Modal */}
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Blog added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="Name">
                                        Blog Title
                                    </label>
                                    <Input
                                     className={`form-control-alternative ${
                                        isValidate.blogName ? (isValidate.blogName?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                    }`}
                                        type="text"
                                        name="blogName"
                                        onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                        defaultValue={selectedRow.blogName || ""}
                                    />
                                      {isClicked && isValidate.blogName && isValidate.blogName?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogName}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Blog Description:
                                    </label>
                                    <textarea
                                         className={`form-control-alternative ${
                                            isValidate.blogDescription ? (isValidate.blogDescription?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="Branch"
                                        rows={6}
                                        style={{ width: "100%" }}
                                        placeholder="Please Enter description"
                                        name="blogDescription"
                                        onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                        defaultValue={selectedRow.blogDescription || ""}
                                    />
                                      {isClicked && isValidate.blogDescription && isValidate.blogDescription?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogDescription}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Branch"
                                    >
                                        Blog Subject:
                                    </label>
                                    <textarea
                                         className={`form-control-alternative ${
                                            isValidate.blogSubject ? (isValidate.blogSubject?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                        }`}
                                        id="Branch"
                                        rows={6}
                                        style={{ width: "100%" }}
                                        placeholder="Please Enter description"
                                        name="blogSubject"
                                        onChange={(e) => handleEditInputChange(e.target.name, e.target.value)}
                                        defaultValue={selectedRow.blogSubject || ""}
                                    />
                                      {isClicked && isValidate.blogSubject && isValidate.blogSubject?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogSubject}</small>
                                )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="serviceimage">Blog Image</Label>
                                    <Input type="file" id="serviceimage" name="blogThumbnail" onChange={(e) => handleEditInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" onBlur={() => setIsValidate({ ...isValidate, blogThumbnail: true })}
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                    Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedImageUrl || selectedRow.blogThumbnail) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedRow?.blogCode} imageUrl={selectedRow?.blogThumbnail ? selectedRow?.blogThumbnail : updatedImageUrl} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                      {isClicked && isValidate.blogThumbnail && isValidate.blogThumbnail?.length > 0 && (
                                    <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.blogThumbnail}</small>
                                )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col lg="12">
                            {selectedRow.blog_list?.map((story, index) => (
                                <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', position: 'relative' }}>
                                    <Button size="sm" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleEditRemoveStory(index)}>
                                        X
                                    </Button>
                                    <FormGroup>
                                        <label>Blog Title:</label>
                                        <Input
                                            type="text"
                                            name="bb_story_title"
                                            value={story.bb_story_title || ""}
                                            onChange={(e) => handleEditStoryInputChange(index, 'bb_story_title', e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Blog Description:</label>
                                        <Editor
                                            key={index}
                                            onTextChange={(e) => handleEditStoryInputChange(index, 'bb_story_description', e.htmlValue)}
                                            style={{ height: '100px' }}
                                            name="bb_story_description"
                                            value={story.bb_story_description || ""}
                                        />
                                    </FormGroup>
                                </div>
                            ))}
                        </Col>
                        <Button style={{ float: "right" }} color="info" onClick={handleEditNewStoryPoint}>
                            + Add Story
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                    <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                    <ModalBody className="bg-secondary">
                        Are you sure you want to delete this row?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Yes
                        </Button>{" "}
                        <Button color="secondary" onClick={closeDeleteModal}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default Blog;