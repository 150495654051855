import Index from "./views/Index.js";
import Profile from "./views/examples/Profile.js";
import Register from "./views/examples/Register.js";
import Login from "./views/examples/Login.js";
import Tables from "./views/examples/Tables.js";
import Icons from "./views/examples/Icons.js";
import Services from "./views/examples/Services.js";
import Products from "./views/examples/Products.js";
import About from "./views/examples/About.js";
import Banner from "./views/examples/Banner.js";
import Award from "./views/examples/Award.js";
import Testimonial from "./views/examples/Testimonial.js";
import News from "./views/examples/News.js";
import CaseStudies from "./views/examples/CaseStudies.js";
import Blog from "./views/examples/Blog.js"
import Contact from "./views/examples/Contact.js"
import Leadership from "./views/examples/Leadership.js"
import Clients from "./views/examples/Clients.js"
import OfficeLocations from "./views/examples/OfficeLocations.js"
import Career from "./views/examples/Career.js"
import Milestones from "./views/examples/Milestones.js";
import SuccessStories from "./views/examples/SuccessStories.js";
import JobDetails from "./views/examples/JobDetails.js";
import LifeatFss from "./views/examples/LifeatFss.js";

var routes = [
 
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  
  {
    path: "/award",
    name: "Award",
    icon: "ni ni-circle-08 text-pink",
    component: <Award />,
    layout: "/admin",
  },
  {
    path: "/testimonial",
    name: "Testimonial",
    icon: "ni ni-circle-08 text-pink",
    component: <Testimonial />,
    layout: "/admin",
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "ni ni-circle-08 text-pink",
    component: <Blog />,
    layout: "/admin",
  },
  {
    path: "/news",
    name: "News",
    icon: "ni ni-circle-08 text-pink",
    component: <News />,
    layout: "/admin",
  },
  
];
export default routes;
