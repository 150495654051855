import { useState, useEffect } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CustomInput,
    UncontrolledTooltip,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Table, Alert, FormText
} from "reactstrap";
import ReactPaginate from 'react-js-pagination';
import axiosInstance from '../../axiosInterceptor';
import MyS3Component from "../../components/s3/s3";
// core components
// import Header from "components/Headers/Header.js";
//import Table from "../../components/Table/Table";

{/*const data = [
    { id: 1, icons: 'John Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 1', action: 'edit,delete', status: '' },
    { id: 2, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 2', action: 'edit,delete', status: 'inactive' },
    { id: 3, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 3', action: 'edit,delete', status: 'active' },
    { id: 4, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 4', action: 'edit,delete', status: 'inactive' },
    { id: 5, icons: 'Jane Doe', Description: 'It is a long established fact that a reader will be distracted.', title: 'Title 5', action: 'edit,delete', status: 'active' },
    // Add more data as needed
];
*/}

const columns = ['Author Name', 'Author Designation', 'Author Image', 'Author Description', 'action'];
const column = ['testimonial_auth_name', 'testimonial_auth_designation', 'testimonial_auth_image', 'testimonial_description', 'action']
const Testimonial = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const itemsPerPage = 5; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    console.log("stateformdata", selectedRow);
    const [successModal, setSuccessModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [formData, setFormData] = useState({});
    const [TestimonialData, SetTestimonialData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const [stateFormData, setFormData] = useState({});
    const [selectedTestimonialData, setSelectedTestimonialData] = useState({})
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [updatedAuthorImage, setupdatedAuthorImage] = useState({})
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = TestimonialData?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    };
    const [isClicked, setIsClicked] = useState(false);
    const [isValidate, setIsValidate] = useState({ testimonial_description: false, testimonial_auth_name: false, testimonial_auth_designation: false, testimonial_auth_image: false, })
    useEffect(() => {
        TestimonialGetApi()
    }, []);
    console.log("TestimonialData", TestimonialData)
    const handleRemoveImage = () => {
        setIsImageVisible(false);
    };
    const token = localStorage.getItem('JWT');

    const TestimonialGetApi = () => axiosInstance.get("/testimonial/testimonialRetrieve").then(response => {
        console.log("Testimonial response DATA", response.data);
        SetTestimonialData(response.data.data);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = response.data?.data?.slice(indexOfFirstItem, indexOfLastItem);
        setTableData(currentItems);
    }).catch(error => {
        console.log("Error from API", error);
    })


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (TestimonialData && TestimonialData.data) ? TestimonialData.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    //console.log("data", data, columns);
    const modalHeaderTag = 'h2';
    const openEditModal = async (row) => {
        setSelectedRow(row);
        setEditModalOpen(true);
        try {
            const response = await axiosInstance.get(`/testimonial/testimonialRetrieve?testimonial_code=${row.testimonial_code}`);
            setSelectedTestimonialData(response.data.data[0]);
        } catch (error) {
            console.log("Error fetching service data by code:", error);
        }
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
    };
    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setDeleteModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (file && !allowedTypes.includes(file.type)) {
            alert('Please select a valid image file (PNG or JPG).');
            e.target.value = '';
        } else {
            handleInputChange(e.target.name, file);
        }
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };
    const handleCreate = async () => {
        setIsClicked(true);

        // Validation checks
        const validationErrors = {};
        if (stateFormData.testimonial_description?.length < 5) {
            validationErrors.testimonial_description = "Input must be at least 5 characters long";
        }
        if (!stateFormData.testimonial_description) {
            validationErrors.testimonial_description = 'Please fill this field';
        }
        if (stateFormData.testimonial_auth_name?.length < 5) {
            validationErrors.testimonial_auth_name = "Input must be at least 5 characters long";
        }
        if (!stateFormData.testimonial_auth_name) {
            validationErrors.testimonial_auth_name = 'Please fill this field';
        }
        if (stateFormData.testimonial_auth_designation?.length < 5) {
            validationErrors.testimonial_auth_designation = "Input must be at least 5 characters long";
        }
        if (!stateFormData.testimonial_auth_designation) {
            validationErrors.testimonial_auth_designation = 'Please fill this field';
        }
        if (!stateFormData.testimonial_auth_image) {
            validationErrors.testimonial_auth_image = 'Please fill this field';
        }
        setIsValidate(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "x-auth-token": token
            }
        };
        const api = "/testimonial/testimonialCreation";
        try {
            const response = await axiosInstance.post(api, stateFormData, config);
            console.log("Testimonial creation response DATA", response.data);
            TestimonialGetApi();
            toggle();
            setFormData({});
            // Display success popup
        } catch (error) {
            console.log("Testimonial creation Error from API", error);
            // Handle error if needed
        }
    };


    const handleEdit = async () => {
        setIsClicked(true);
        const validationErrors = {};

        const validationRules = {
            testimonial_auth_name: { required: true, minLength: 5 },
            testimonial_description: { required: true, minLength: 5 },
            testimonial_auth_designation: { required: true, minLength: 5 },
            testimonial_auth_image: { required: true }
        };

        for (const [fieldName, rules] of Object.entries(validationRules)) {
            const value = stateFormData[fieldName] || selectedRow[fieldName];
            if (rules.required && (!value || value === "")) {
                validationErrors[fieldName] = 'Please fill this field';
            } else if (rules.minLength && value && value.length < rules.minLength) {
                validationErrors[fieldName] = `Please fill this field atleast ${rules.minLength} characters long`;
            }
        }

        setIsValidate(validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            return; // Stop further execution if there are validation errors
        }
        const formData = new FormData();
        formData.append("testimonial_code", selectedRow.testimonial_code);
        formData.append("testimonial_auth_name", stateFormData.testimonial_auth_name || selectedRow.testimonial_auth_name);
        formData.append("testimonial_description", stateFormData.testimonial_description || selectedRow.testimonial_description);
        formData.append("testimonial_auth_image", stateFormData.testimonial_auth_image || "");
        formData.append("testimonial_auth_designation", stateFormData.testimonial_auth_designation || selectedRow.testimonial_auth_designation);

        try {
            const response = await axiosInstance.put("/testimonial/testimonialEdit", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token": token
                }
            });
            const updatedAuthorImage = response.data.testimonial_auth_image;
            setupdatedAuthorImage(updatedAuthorImage);

            console.log("Edit award response:", response.data);
            TestimonialGetApi();
            closeEditModal();
            } catch (error) {
                console.error("Error updating award:", error);
                
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`/testimonial/testimonialDelete?testimonial_code=${selectedRow.testimonial_code}`, {
                headers: {
                    'x-auth-token': token
                }
            }
            );
            console.log("Delete testimonial response:", response.data);
            TestimonialGetApi();
            closeDeleteModal();
        } catch (error) {
            console.log("Error deleting testimonial:", error);
           
        }
    };
    const handleInputChange = (inputName, val) => {
        console.log("Event data change", inputName, "   val", val);
        if (inputName === 'testimonial_auth_image') {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (val && !allowedTypes.includes(val.type)) {
                alert('Please select a valid image file (PNG or JPG).');
            }
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [inputName]: val,
                }
            })
            setIsValidate((prevValidation) => ({
                ...prevValidation,
                [inputName]: !!val,
            }));

        }
        setFormData((prevData) => {
            return {
                ...prevData,
                [inputName]: val,
            }
        })
        setIsValidate((prevValidation) => ({
            ...prevValidation,
            [inputName]: !!val, // Update validation status based on whether the field has a value
        }));
    };
    return (
        <>
            <Container className="mt-5" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h2 className="mb-0">Testimonial</h2>
                                <Button color="primary" onClick={toggle}>Add</Button>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {columns?.map((column, index) => (
                                                <th key={index}>{column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData?.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {column?.map((column, columnIndex) => (
                                                    <td key={columnIndex}>
                                                        {column === 'action' ? (
                                                            <>
                                                                <button className="btn btn-primary" onClick={() => openEditModal(row)}>
                                                                    edit
                                                                </button>
                                                                <button className="btn btn-default" onClick={() => openDeleteModal(row)}>
                                                                    delete
                                                                </button>
                                                            </>
                                                        ) :
                                                            column === 'testimonial_auth_image' ? (
                                                                <MyS3Component key={row['testimonial_code']} imageUrl={row['testimonial_auth_image']} />
                                                            ) :
                                                                column === "testimonial_status" ? (
                                                                    <>
                                                                        <CustomInput type="switch" id={`customSwitch${rowIndex}`} checked={row['testimonial_status'] === 'ACTIVE'} />
                                                                    </>
                                                                ) :
                                                                    (
                                                                        row[column]
                                                                    )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    TestimonialData?.length > itemsPerPage && (
                                        <ReactPaginate
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={TestimonialData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                        />
                                    )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader toggle={toggle} tag={modalHeaderTag}>Add Testimonial</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Name"
                                    >
                                        Author Name
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.testimonial_auth_name ? (isValidate.testimonial_auth_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue=""
                                        id="Description"
                                        placeholder="Please Enter Name"
                                        type="text"
                                        name="testimonial_auth_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onBlur={() => setIsValidate({ ...isValidate, testimonial_auth_name: true })}
                                        required
                                        aria-describedby="name-error"
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.testimonial_auth_name && isValidate.testimonial_auth_name?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_auth_name}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Designation"
                                    >
                                        Designation
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.job_title ? (isValidate.job_title?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue=""
                                        id="subject"
                                        placeholder="Please Enter Designation"
                                        type="text"
                                        onBlur={() => setIsValidate({ ...isValidate, testimonial_auth_designation: true })}
                                        required
                                        aria-describedby="name-error"
                                        name="testimonial_auth_designation"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.testimonial_auth_designation && isValidate.testimonial_auth_designation?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_auth_designation}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label for="testimonial_auth_image">Author Image</label>
                                    <Input type="file" id="testimonial_auth_image" name="testimonial_auth_image" onBlur={() => setIsValidate({ ...isValidate, testimonial_auth_image: true })} onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg" required aria-describedby="name-error" />
                                    <FormText color="muted">

                                        Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isClicked && isValidate.testimonial_auth_image && isValidate.testimonial_auth_image?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_auth_image}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Designation"
                                    >
                                        Message
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.testimonial_description ? (isValidate.testimonial_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue=""
                                        id="subject"
                                        placeholder="Please Enter Message"
                                        type="textarea"
                                        onBlur={() => setIsValidate({ ...isValidate, testimonial_description: true })}
                                        required
                                        aria-describedby="name-error"
                                        name="testimonial_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.testimonial_description && isValidate.testimonial_description?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_description}</small>
                                    )}
                                </FormGroup>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleCreate}>
                            Add
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={successModal} toggle={toggleSuccessModal} centered>
                    <ModalBody>
                        <Alert color="success">Testimonial added successfully!</Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccessModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={isEditModalOpen} toggle={closeEditModal} centered size="lg">
                    <ModalHeader toggle={closeEditModal} tag={modalHeaderTag}>Edit Row</ModalHeader>
                    <ModalBody className="bg-secondary">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Name"
                                    >
                                        Author Name
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.testimonial_auth_name ? (isValidate.testimonial_auth_name?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue={selectedTestimonialData.testimonial_auth_name || ""}
                                        id="Description"
                                        placeholder="Please Enter Name"
                                        type="text"
                                        name="testimonial_auth_name"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.testimonial_auth_name && isValidate.testimonial_auth_name?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_auth_name}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Designation"
                                    >
                                        Designation
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.testimonial_auth_designation ? (isValidate.testimonial_auth_designation?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue={selectedTestimonialData.testimonial_auth_designation || ""}
                                        id="subject"
                                        placeholder="Please Enter Designation"
                                        type="text"
                                        name="testimonial_auth_designation"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.target.value === '' && !isNaN(parseInt(e.key))) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {isClicked && isValidate.testimonial_auth_designation && isValidate.testimonial_auth_designation?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_auth_designation}</small>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label for="TestimonialThumbnail">Author Image</label>
                                    <Input type="file"
                                        id="testimonial_auth_image"
                                        name="testimonial_auth_image"
                                        onBlur={() => setIsValidate({ ...isValidate, testimonial_auth_image: true })}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                                        accept=".png, .jpg, .jpeg"
                                        required
                                        aria-describedby="name-error" />
                                    <FormText color="muted">
                                        Please select .png or .jpg images only within 1024x768px.
                                    </FormText>
                                    {isImageVisible && (updatedAuthorImage || selectedTestimonialData.testimonial_auth_image) ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <MyS3Component key={selectedTestimonialData?.testimonial_code} imageUrl={selectedTestimonialData?.testimonial_auth_image ? selectedTestimonialData?.testimonial_auth_image : updatedAuthorImage} />
                                            <Button size="sm" style={{ position: 'absolute', top: 0, right: "-40px" }} onClick={handleRemoveImage}>
                                                X
                                            </Button>
                                        </div>
                                    ) : null}
                                    {isClicked && isValidate.testimonial_auth_image && isValidate.testimonial_auth_image?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_auth_image}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="Description"
                                    >
                                        Message
                                    </label>
                                    <Input
                                        className={`form-control-alternative ${isValidate.testimonial_description ? (isValidate.testimonial_description?.length > 0 ? 'is-invalid' : 'is-valid') : ''
                                            }`}
                                        defaultValue={selectedTestimonialData.testimonial_description || ""}
                                        id="subject"
                                        placeholder="Please Enter Description"
                                        type="textarea"
                                        name="testimonial_description"
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    />
                                    {isClicked && isValidate.testimonial_description && isValidate.testimonial_description?.length > 0 && (
                                        <small className="text-danger my-0 d-block fz-12px mt-3 fw-100">{isValidate.testimonial_description}</small>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleEdit}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={closeEditModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Delete Modal */}
                <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered size="lg">
                    <ModalHeader toggle={closeDeleteModal} tag={modalHeaderTag}>Delete</ModalHeader>
                    <ModalBody className="bg-secondary">
                        Are you sure you want to delete this Testimonial?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Yes
                        </Button>{" "}
                        <Button color="secondary" onClick={closeDeleteModal}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default Testimonial;