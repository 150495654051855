import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import "./App.css";

const ProtectedRoute = () =>  {
  const location = useLocation();
  console.log("location ", location);
    let auth = localStorage.getItem("JWT");  
    console.log("auth data", auth);
    if(!auth) {
      console.log("111111111111111");
    //   navigate("/auth");
      return <Navigate replace to="/auth" />
    } else {
      if(location.pathname === "/") {
        return <Navigate replace to="/admin" />
      }
      return <Outlet />;
    }
};

function App() {
  return (
    <BrowserRouter>
      <Routes>    
        <Route path="/auth/*" element={<AuthLayout />} />  
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/admin/*" element={<AdminLayout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App